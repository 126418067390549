import breakpoints from '../../../theme/breakpoints'
import theme from '../../../theme/news12'

import css from 'styled-jsx/css'

export const providerStyle = css`
  .wrapper-providers {
    padding: 24px 120px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  a, h2 {
    background-color: #000000;
  }

  .container-providers {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  img {
    width: auto;
    height: 40px;
    padding: 6px;
  }

  h2 {
    color: #ffffff;
    font-weight: 400;
    font-size: 36px;
    border: none;
    font-style: normal;
    text-align: center;
    margin-bottom: 44px;
  }

  a {
    text-decoration: none;
    margin: 26px;
		cursor: pointer;
  }

  @media only screen and (max-width:1023px) {
    .wrapper-providers {
      padding: 0px 0 80px;
    }

    h2 {
      margin: 30px 0;
      margin-bottom: 26px;
    }
  }
  
  @media only screen and (max-width:767px) {
    .wrapper-providers {
      padding: 10px 0 80px;
    }

    .container-providers {
      flex-direction: column;
    }

    h2 {
      margin: 21px 0;
      font-size: 32px;
      text-transform: inherit;
      margin-bottom: 26px;
    }

    a {
      margin: 18px 0;
    }

    img {
      height: 30px;
    }
  }

  @media only screen and (max-width:539px) {
    h2 {
      margin: 21px 0;
      font-size: 24px;
    }

    a {
      margin: 15px 0;
    }

    img {
      height: 25px;
    }
  }
`

export default css`
  :global(.vjs-live-display) {
    background: ${theme.colors.royalBlue};
  }

  :global(.is_live) :global(.vjs-current-time), :global(.is_live) :global(.vjs-time-control) {
    display: none;
  }

  .wrapper {
    background-color: ${theme.colors.mineShaft};
    display: flex;
    justify-content: center;
    padding: 48px 0 66px 0;
    position: relative;
    z-index: 0;
  }

  .screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1024px;
    width: 100%;
    background-color: #000000;
  }

  @media only screen and (max-width:1024px) {
    .wrapper {
      padding: 0;
    }
  }
`
export const loginError = css`
  div {
    width:82%;
    margin: 18px auto;
    text-align:center;
  }
  
  div > .login-text {
    font-size: 15px;
    color:#FFFFFF;
    background: ${theme.colors.alertRed};
    padding: 10px;
    width: auto;
    display: inline-block;
  }
  
  @media only screen and (min-width:${breakpoints.sm}px) {
    div {
      width:65%;
    }
  }
  
  @media only screen and (min-width:${breakpoints.md}px) {
    div {
      width:75%;
      margin-bottom: 50px;
    }
    
    div > .login-text {
      font-size: 18px;
      padding: 13px 16px;
    }
  }
  
  @media only screen and (min-width:${breakpoints.lg}px) {
    div {
      width:100%;
      margin-bottom: 50px;
    }
  }
`
