'use client'

import { useEffect } from 'react'

const AdobePdfViewer = ({ index = 0, url }) => {
  useEffect(() => {
    let script

    async function fetchData() {
      const response = await fetch('/api/adobe')
      const { key } = await response.json()
      script = document.createElement('script')
      script.src = 'https://documentservices.adobe.com/view-sdk/viewer.js'
      script.async = true
      document.body.appendChild(script)

      script.onload = () => {
        document.addEventListener('adobe_dc_view_sdk.ready', function () {
          const adobeDCView = new AdobeDC.View({
            clientId: key,
            divId: `adobe-dc-view-${index}`
          })

          adobeDCView.previewFile(
            {
              content: { location: { url: url } },
              metaData: { fileName: url.substring(url.lastIndexOf('/') + 1) }
            },
            { defaultViewMode: 'FIT_PAGE' }
          )
        })
      }
    }

    fetchData()

    return () => {
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [url])

  return <div style={{ width: '100%', height: '600px', marginBottom: '30px' }} id={`adobe-dc-view-${index}`} />
}

export default AdobePdfViewer
