import { FlexContainer, HoverableImageLink } from '@cheddartv/storybook-news'

import { WhereToWatchPlatform } from '../../types'

type PlatformsListProps = {
  platforms: WhereToWatchPlatform[]
}

const PlatformsList = ({ platforms }: PlatformsListProps) => {
  return (
    <FlexContainer justifyContent={['center', 'start']} gap='1.5rem' flexWrap='wrap'>
      {platforms.map(({ id, backgroundColor, backgroundColorHover, logo, logoHover, href, name }) => (
        <HoverableImageLink
          key={id}
          backgroundColor={backgroundColor}
          backgroundHoverColor={backgroundColorHover}
          imageSrc={logo}
          imageHoverSrc={logoHover}
          href={href}
          alt={name}
        />
      ))}
    </FlexContainer>
  )
}

export default PlatformsList
