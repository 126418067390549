'use client'

import style from '../../styles/components/componentMap/live'

import dynamic from 'next/dynamic'

const VideoPlayer = dynamic(() => import('../utilities/VideoPlayer'), { ssr: false })

const VideoEmbed = ({ node, index }) => {
  const caption = node?.attribs?.['data-caption']
  const isLive = node?.attribs?.['data-isLive'] === 'true'
  const src = node?.attribs?.['data-src']
  const poster = node?.attribs?.['data-poster']
  const title = `article-body-video-${index}-${isLive ? 'LIVE' : 'VOD'}`
  const type = src?.split('.')?.pop() === 'mp4' ? 'video/mp4' : 'application/x-mpegURL'
  const className = isLive ? 'article-body-video here' : 'article-body-video'

  return (
    <>
      <div className={className}>
        <VideoPlayer
          poster={poster}
          autoplay={false}
          muted={false}
          isLive={isLive}
          src={src}
          title={title}
          type={type}
        />
      </div>
      {caption && <em>{caption}</em>}
      {isLive && <style>{style}</style>}
    </>
  )
}

export default VideoEmbed
